import {Swiper, SwiperSlide} from "swiper/react"
import {Navigation, Pagination, Mousewheel} from "swiper"
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"
import SwiperGL from "components/swiper/swiper-gl.esm"
import "components/swiper/swiper-gl.scss"
import "./index.scss"
import {useEffect, useRef} from "react"

export default function SwiperComp({animation}) {
	const myRef = useRef(null)
	useEffect(() => {
		localStorage.setItem("swiperIndex", 0)
		const whell = (event) => {
			if (event.deltaY < 0) {
				let index = localStorage.getItem("swiperIndex")
				if (index && Number(index) === 0) {
					animation.reverse()
				}
			}
		}

		let touchstartY = 0
		let touchendY = 0

		function checkDirection() {
			let index = localStorage.getItem("swiperIndex")
			if (index && Number(index) === 0) {
				if (touchendY > touchstartY) {
					animation.reverse()
				}
			}
		}

		const touchstart = (e) => {
			touchstartY = e.changedTouches[0].screenY
		}

		const touchend = (e) => {
			touchendY = e.changedTouches[0].screenY
			checkDirection()
		}

		window.addEventListener("touchstart", touchstart)
		window.addEventListener("touchend", touchend)
		window.addEventListener("wheel", whell)
		return () => {
			window.removeEventListener("wheel", whell)
			window.removeEventListener("touchstart", touchstart)
			window.removeEventListener("touchend", touchend)
		}
	}, [])

	return (
		<>
			<Swiper
				ref={myRef}
				direction={"vertical"}
				speed={1000}
				// freeMode="true"
				pagination={{
					clickable: true,
					el: ".swiper-pagination",
				}}
				mousewheel={{
					forceToAxis: true,
					releaseOnEdges: false,
				}}
				gl={{
					shader: "stretch",
				}}
				spaceBetween="100"
				effect="gl"
				onSlideChangeTransitionStart={(swiperCore) => {
					myRef.current.scrollIntoView()
				}}
				onSlideChangeTransitionEnd={(swiperCore) => {
					// setActiveIndex(swiperCore.activeIndex)
					if (swiperCore.isEnd) {
						document.body.style.overflow = "auto"
						swiperCore.params.mousewheel.releaseOnEdges = true
					} else if (swiperCore.isBeginning) {
						document.body.style.overflow = "hidden"
						swiperCore.params.mousewheel.releaseOnEdges = true
					} else {
						document.body.style.overflow = "hidden"
						swiperCore.params.mousewheel.releaseOnEdges = false
					}
				}}
				onSlideChange={(swiperCore) => {
					const {activeIndex} = swiperCore
					localStorage.setItem("swiperIndex", activeIndex)
					myRef.current.scrollIntoView()
				}}
				modules={[Navigation, Pagination, Mousewheel, SwiperGL]}
				className="mySwiper"
			>
				<div className="swiper-pagination container"></div>
				<SwiperSlide>
					<img className="swiper-gl-image" src="assets/slides/1.webp" alt="banner" />
					<div className="swiper-slide-title container title_container">
						<h2>Enter the 3D immersive world</h2>
						<p>
							Step into a revolutionary virtual realm where your love for films takes center stage. Cineverse is a
							cutting-edge 3D social metaverse designed exclusively for passionate film enthusiasts. Immerse yourself in
							a dynamic and interactive world where cinema comes to life in unprecedented ways.
						</p>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<img className="swiper-gl-image" src="assets/slides/2.webp" alt="banner" />

					<div className="swiper-slide-title container title_container">
						<h2>Meet new friends</h2>
						<p>
							Engage in vibrant conversations and forge meaningful connections with like-minded film enthusiasts from
							around the globe. Join virtual communities dedicated to your favorite genres, eras, and iconic films.
							Discuss theories, share recommendations, and dive into deep conversations about the art of storytelling.
						</p>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<img className="swiper-gl-image" src="assets/slides/3.webp" alt="banner" />

					<div className="swiper-slide-title container title_container">
						<h2>Shop branded merchandise</h2>
						<p>
							Discover a world of exclusive digital collectibles, fan merchandise, and unique rewards within Cineverse.
							Own limited-edition digital items that commemorate special moments, autographed memorabilia, and
							personalized fan experiences. Showcase your fandom with pride and engage in the thriving digital economy,
							where fan-driven marketplaces and unique offerings abound.
						</p>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<img className="swiper-gl-image" src="assets/slides/4.webp" alt="banner" />

					<div className="swiper-slide-title container title_container">
						<h2>Attend film festivals and get latest updates </h2>
						<p>
							Participate in virtual film festivals, where you can showcase your own creations or explore the work of
							emerging filmmakers. Engage in Q&A sessions with directors, attend panel discussions with industry
							experts, and discover the future of filmmaking. Immerse yourself in the immersive atmosphere of our
							film-themed events, workshops, and masterclasses.
						</p>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<img className="swiper-gl-image" src="assets/slides/5.webp" alt="banner" />

					<div className="swiper-slide-title container title_container">
						<h2>Virtual Cinematic Experiences</h2>
						<p>
							Experience the thrill of shared movie-watching like never before. Dive into our virtual cinemas,
							meticulously designed to recreate the ambiance of traditional movie theaters. Enjoy synchronized
							screenings of beloved classics, indie gems, and exclusive premieres. Interact with fellow viewers in
							real-time, sharing reactions and insights that elevate the movie-watching experience to new heights.
						</p>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<img className="swiper-gl-image" src="assets/slides/6.webp" alt="banner" />

					<div className="swiper-slide-title container title_container">
						<h2>Enter game arena, play fun games, and earn rewards</h2>
						<p>
							Cineverse embraces the power of Web3 technology, allowing you to engage with decentralized gaming
							experiences. Experience true ownership of in-game assets, participate in player-driven economies, and
							unlock new possibilities through blockchain integration. Discover a world where film-based social
							interactions meet the innovative gaming opportunities offered by Web3.
						</p>
					</div>
				</SwiperSlide>
			</Swiper>
		</>
	)
}
