import {useLayoutEffect, useState, useRef, useEffect} from "react"
import "./index.scss"
import {Link} from "react-router-dom"
import Offcanvas from "react-bootstrap/Offcanvas"
import Footer from "components/Footer"
import {gsap} from "gsap"

export default function Mobile({setShowCollab}) {
	const [show, setShow] = useState(false)
	const [isScroll, setIsScroll] = useState(false)
	const handleClose = () => setShow(false)
	const tl = useRef()
	const container = useRef()
	const slides = [
		{
			img: "assets/slides/1.webp",
			title: "Enter the 3D immersive world",
			desc: "Step into a revolutionary virtual realm where your love for films takes center stage. Cineverse is a cutting-edge 3D social metaverse designed exclusively for passionate film enthusiasts. Immerse yourself in a dynamic and interactive world where cinema comes to life in unprecedented ways.",
		},
		{
			img: "assets/slides/2.webp",
			title: "Meet new friends",
			desc: "Engage in vibrant conversations and forge meaningful connections with like-minded film enthusiasts from around the globe. Join virtual communities dedicated to your favorite genres, eras, and iconic films. Discuss theories, share recommendations, and dive into deep conversations about the art of storytelling.",
		},
		{
			img: "assets/slides/3.webp",
			title: "Shop branded merchandise",
			desc: "Discover a world of exclusive digital collectibles, fan merchandise, and unique rewards within Cineverse. Own limited-edition digital items that commemorate special moments, autographed memorabilia, and personalized fan experiences. Showcase your fandom with pride and engage in the thriving digital economy, where fan-driven marketplaces and unique offerings abound.",
		},
		{
			img: "assets/slides/4.webp",
			title: "Attend film festivals and get latest updates ",
			desc: "Participate in virtual film festivals, where you can showcase your own creations or explore the work of emerging filmmakers. Engage in Q&A sessions with directors, attend panel discussions with industry experts, and discover the future of filmmaking. Immerse yourself in the immersive atmosphere of our film-themed events, workshops, and masterclasses.",
		},
		{
			img: "assets/slides/5.webp",
			title: "Virtual Cinematic Experiences",
			desc: "Experience the thrill of shared movie-watching like never before. Dive into our virtual cinemas, meticulously designed to recreate the ambiance of traditional movie theaters. Enjoy synchronized screenings of beloved classics, indie gems, and exclusive premieres. Interact with fellow viewers in real-time, sharing reactions and insights that elevate the movie-watching experience to new heights.",
		},
		{
			img: "assets/slides/6.webp",
			title: "Enter game arena, play fun games, and earn rewards",
			desc: "Cineverse embraces the power of Web3 technology, allowing you to engage with decentralized gaming experiences. Experience true ownership of in-game assets, participate in player-driven economies, and unlock new possibilities through blockchain integration. Discover a world where film-based social interactions meet the innovative gaming opportunities offered by Web3.",
		},
	]

	useLayoutEffect(() => {
		container.current.style.height = window.innerHeight + "px"
		const ctx = gsap.context(() => {
			tl.current = gsap
				.timeline({
					defaults: {duration: 1},
					scrollTrigger: {
						trigger: ".mobile_hero_section",
						scrub: 0.5,
						start: "top top",
						end: `+=${window.innerWidth * 3}`,
						// pin: true,
					},
				})
				.to(".ship_image", {left: 0, x: -65}, "ship")
				// .to(".road_img", {scale: 1.2, bottom: 100, right: 0}, "ship")
				.to(".top_image", { scale: 1.2 }, "ship")
				.to(".text_section", {opacity: 0, duration: 0.2}, "ship")
		}, container) // <- Scope!
		return () => ctx.revert() // <- Cleanup!
	}, [])

	useEffect(() => {
		const scrollCheck = (e) => {
			if (window.innerHeight < window.scrollY + 80) {
				setIsScroll(true)
			} else {
				setIsScroll(false)
			}
		}
		window.addEventListener("scroll", scrollCheck)
		return () => {
			window.removeEventListener("scroll", scrollCheck)
		}
	}, [])

	return (
		<>
			<header className={`mobile_header_1 ${isScroll && "scrolled"}`}>
				<img
					src="assets/icons/menuIcon.png"
					alt="menu icon"
					className="menuIcon"
					onClick={() => {
						setShowCollab(false)
						setShow((prev) => !prev)
					}}
				/>
				<div>
					{!isScroll ? (
						<img src="assets/mobile/header/cineverse_text.png" alt="hero" />
					) : (
						<img src="assets/icons/mainLogo.png" className="main_logo" alt="hero" />
					)}
				</div>
			</header>
			<div className="mobile_hero_section" ref={container}>
				<img src="assets/mobile/hero/hero.png" className="mainbackground" alt="hero" />
				{/* <img src="assets/mobile/hero/top.png" className="top_image" alt="hero" /> */}
				{/* <img src="assets/mobile/hero/road.png" className="road_img" alt="hero" /> */}
				<img src="assets/mobile/hero/ship.png" className="ship_image" alt="hero" />
				<div className="text_section">
					<div>
						<h1>The Multiverse for Entertainment</h1>
						<p>
							The World’s first Metaverse platform to bring Celebs
							and Fans together, play games and earn rewards.
						</p>
					</div>
				</div>
			</div>
			<div className="m_slider">
				{slides.map((slide, i) => (
					<div className="mobile_slide_card" key={`m-slide${i}`}>
						<img src={slide.img} alt={slide.title} />
						<div className="mobile_slide_card_body">
							<div className="title">{slide.title}</div>
							<div className="desc">{slide.desc}</div>
						</div>
					</div>
				))}
			</div>
			<Footer />
			<Offcanvas show={show} onHide={handleClose} className="menu_off_canvas">
				<Offcanvas.Body>
					<span onClick={() => setShowCollab(true)}>Collaborations</span>
					<Link to="">Marketplace</Link>
					<Link to="">New Collection</Link>
					{/*<Link to="">Community</Link>*/}
					{/*<Link to="">TEAM</Link>
					<Link to="">contact</Link>
					<Link to="">terms of service</Link>
					<Link to="">privacy policy</Link>*/}
				</Offcanvas.Body>
			</Offcanvas>
		</>
	)
}
