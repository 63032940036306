import "./index.scss"
import {useRef, useState, useMemo, useEffect} from "react"
import {gsap} from "gsap"
import {ScrollTrigger} from "gsap/ScrollTrigger"
import {ScrollToPlugin} from "gsap/ScrollToPlugin"
import {Container, Modal, Row, Col} from "react-bootstrap"
import {Link} from "react-router-dom"
import Fotter from "components/Footer"
import SwiperComp from "components/SwiperComp"
import Mobile from "components/Mobile"
import Offcanvas from "react-bootstrap/Offcanvas"

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger)
ScrollTrigger.defaults({
	toggleActions: "restart pause resume pause",
})

export default function HomePage() {
	const app = useRef()
	const [isScroll, setIsScroll] = useState(false)
	const [show, setShow] = useState(false)
	const [showMenu, setShowMenu] = useState(false)
	const [loader, setLoader] = useState(true)
	const [touchstartY, setTouchstartY] = useState(0)
	const [touchendY, setTouchendY] = useState(0)

	function checkDirection() {
		if (touchendY < touchstartY) {
			if (!isScroll) {
				createTimeLine()
				setIsScroll(true)
			} else {
				timeline.play()
			}
		}
	}

	const timeline = useMemo(() => gsap.timeline({defaults: {duration: 1}}), [])
	const createTimeLine = () => {
		timeline
			.to(".text_section", {opacity: 0, duration: 0.5})
			.to(".gradient", {opacity: 0, duration: 0.2}, "<")
			.to(".header", {top: "-100%"}, "sameTime")
			.to(".header2", {top: 0}, "sameTime")
			.to(
				".road",
				{
					xPercent: -50,
					y: -450,
					scale: 3,
				},
				"sameTime",
			)
			.to(
				".spaceman",
				{
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					height: "100vh",
					width: "100vw",
				},
				"sameTime",
			)
			.to(
				".road",
				{
					opacity: 0,
				},
				">",
			)
			.to(
				".space",
				{
					scale: 1.1,
				},
				"<=",
			)
			.from(".mySwiper", {opacity: 0}, "sameTime")
			.to(".mySwiper", {
				position: "absolute",
				top: 0,
				opacity: 1,
				duration: 2,
			})
	}

	useEffect(() => {
		const loaderFnc = (e) => {
			setTimeout(() => {
				setLoader(false)
			}, 2000)
		}

		if (document.readyState === "complete") {
			loaderFnc()
		} else {
			window.addEventListener("load", loaderFnc)
		}

		return () => {
			window.removeEventListener("load", loaderFnc)
		}
	}, [])

	if (loader) {
		return (
			<div className="loader">
				<img src="assets/icons/spin.svg" alt="loader" />
				<img src="assets/icons/mainLogo.png" alt="loader text" />
				{/*<h2 data-text={"Loading"}>Loading</h2>*/}
				{/*<div className="box">
					<div className="loader_box_first"></div>
					<div className="loader_box_second"></div>
					<div className="loader_box_third"></div>
					<div className="loader_box_fourth"></div>
					<div className="loader_box_five"></div>
				</div>*/}
			</div>
		)
	} else {
		return (
			<>
				{window.innerWidth < 768 ? (
					<Mobile setShowCollab={setShow} />
				) : (
					<div ref={app}>
						<header className="header">
							<Container>
								<img src="assets/icons/logo.svg" alt="logo" />
								<div className="links">
									<div className="link">
										Marketplace
										<span className="tooltiptex">Coming Soon</span>
									</div>
									<div className="link">
										New Collection
										<span className="tooltiptex">Coming Soon</span>
									</div>
									<div className="link">
										Community
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="16"
											height="16"
											fill="currentColor"
											class="bi bi-caret-down"
											viewBox="0 0 16 16"
										>
											<path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z" />
										</svg>
										<span className="tooltiptex">
											<Link target="_blank" to="https://www.instagram.com/thecineverse_/?igshid=MzRlODBiNWFlZA%3D%3D">
												Instagram
											</Link>
											<Link target="_blank" to="https://twitter.com/thecineverse?s=21">
												Twitter
											</Link>
										</span>
									</div>
								</div>
							</Container>
						</header>
						<header className="header2">
							<Container>
								<img
									src="assets/icons/menuIcon.png"
									alt="menu icon"
									onClick={() => {
										setShow(false)
										setShowMenu((prev) => !prev)
									}}
								/>
								<img
									src="assets/icons/mainLogo.png"
									style={{cursor: "pointer"}}
									onClick={() => {
										timeline.reverse()
										setShow(false)
										setShowMenu(false)
									}}
									alt="logo"
								/>
							</Container>
						</header>
						<section
							className="hero_section"
							id="ImgWrapper"
							onTouchStart={(e) => setTouchstartY(e.changedTouches[0].screenY)}
							onTouchEnd={(e) => checkDirection(e.changedTouches[0].screenY)}
							onWheel={(e) => {
								if (!isScroll) {
									createTimeLine()
									setIsScroll(true)
								} else {
									timeline.play()
								}
							}}
						>
							<div className="text_section" id="hero_text">
								<Container className="mainHeading">
									<h1>
										The Multiverse for <br /> Entertainment
									</h1>
									<p>
										The World’s first Metaverse platform to
										<br /> bring Celebs and Fans together, play
										<br /> games and earn rewards.
									</p>
								</Container>
							</div>
							<img src="assets/landingPage/space.webp" className="space" alt="banner" />
							<img src="assets/landingPage/spaceman.webp" className="spaceman" alt="banner" />
							<img src="assets/landingPage/road.webp" className="road" alt="banner" />
							<div className="gradient"></div>
						</section>
						<SwiperComp animation={timeline} />
						<Fotter />
					</div>
				)}

				<Modal show={show} fullscreen={true} onHide={() => setShow(false)} className="partnerModal">
					<Modal.Body>
						<div className="gradient"></div>
						<Container>
							<h1>Collaborations</h1>
							<Row>
								<Col xs={12} md={4}>
									<div className="partner_card">
										{/* <div className="image">
										</div> */}
										<img src="assets/partners/mavid.svg" alt="patner" />
										{/* <img className="swiper-gl-image" src="images/banner/5.png" alt="banner" /> */}
										{/*<div className="title">
											Mavid Studio
										</div>*/}
										{/*<div className="desc">
											360° Metaverse Solutions for the
											New-age Customer
										</div>*/}
									</div>
								</Col>
							</Row>
						</Container>
					</Modal.Body>
				</Modal>

				<Offcanvas show={showMenu} onHide={() => setShowMenu(false)} className="web_menu_model">
					<Container>
						<span onClick={() => setShow(true)}>Collaborations</span>
						<Link to="">Marketplace</Link>
						<Link to="">New Collection</Link>
					</Container>
				</Offcanvas>
			</>
		)
	}
}
