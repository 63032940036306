import "./index.scss"
import {Link} from "react-router-dom"
import {Row, Col, Container} from "react-bootstrap"

export default function Footer() {
	return (
		<footer>
			<Container>
				<Row className="footer_row">
					<Col xs={12} sm={12} md={4} lg={4}>
						<div className="footer_left_section">
							<img src="assets/icons/blackLogo.svg" alt="logo" />
							<div className="terms_and_conditions">
								<Link to="/">TERMS OF SERVICE</Link> <span></span> <Link to="/">Privacy POLICY</Link>
							</div>
						</div>
					</Col>
					<Col xs={6} sm={6} md={4} lg={4}>
						<div className="footer_mid_section">
							<Link to="/">Marketplace</Link>
							{/*<Link to="/">Community</Link>*/}
							<Link to="/">New Collection</Link>
							{/*<Link to="/">TEAM</Link>*/}
						</div>
					</Col>
					<Col xs={6} sm={6} md={4} lg={4}>
						<div className="footer_right_section">
							{/*<Link to="/">Contact</Link>*/}
							{/*<Link to="/">Media Coverage</Link>*/}
							<p>Socials</p>
							<div className="icons_section">
								{/* <image src="footer-icons/facebook.svg" /> */}
								<Link target="_blank" to="https://twitter.com/thecineverse?s=21">
									<img src="assets/icons/twitter.svg" alt="twitter logo" />
								</Link>
								{/* <image src="footer-icons/discord.svg" /> */}
								<Link target="_blank" to="https://www.instagram.com/thecineverse_/?igshid=MzRlODBiNWFlZA%3D%3D">
									<img src="assets/icons/insta.svg" alt="instagram logo" />
								</Link>
								{/* <image src="footer-icons/telegram.svg" />
                                <image src="footer-icons/linkedin.svg" /> */}
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		</footer>
	)
}
